<template>
  <div class="ai-studio-container">
    <div class="row">
      <div class="ai-studio-left-wrap col-xl-4">
        <!-- <img :src="''"> -->
        <h1>Audio Clip Recorder</h1>
        <p><button id="record">Record</button></p>
        <p>
            <select id="lang" @change="changeLang">
                <option value="ko-KR">한글</option>
                <option value="en-US">영어</option>
            </select>
        </p>
        <div id="sound-clip"></div>
      </div>
      <div class="ai-studio-center-wrap col-xl-7">
        <!-- <video controls>
          <source :src="''">
        </video> -->
        <div style="position:absolute">
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
          글자글자글자글자글자글자글자글자글자글자글자글자글자글자글자
          <br/>
        </div>
        <div id="container"></div>
      </div>
      <div class="ai-studio-right-wrap col-xl-1">
        <input type="file" class="upload-mask-image" accept="image/*" @change="handleImageSource">
        <input type="file" class="upload-original-video" accept="video/*" @change="handleVideoSource">
        <button @click="translateVideo">변환하기</button>
      </div>
    </div>
    <div class="row">
      <div class="ai-studio-center-wrap col-xl-12">
        <div class="chat_window">
            <div class="top_menu">
                <div class="buttons">
                    <div class="button close_button"></div>
                    <div class="button minimize"></div>
                    <div class="button maximize"></div>
                </div>
                <div class="title">Kochat 데모</div>
            </div>
            <ul class="messages"></ul>
            <div class="bottom_wrapper clearfix">
                <div class="message_input_wrapper">
                    <input class="message_input"
                        @keyup="onClickAsEnter"
                        placeholder="내용을 입력하세요."/>
                </div>

                <div class="send_message"
                    id="send_message"
                    @click="onSendButtonClicked">

                    <div class="icon"></div>
                    <div class="text">보내기</div>
                </div>

            </div>
        </div>
        <div class="message_template">
            <li class="message">
                <div class="avatar"></div>
                <div class="text_wrapper">
                    <div class="text"></div>
                </div>
            </li>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
    .chat_window {
        position: absolute;
        width: calc(100% - 20px);
        max-width: 800px;
        height: 500px;
        border-radius: 10px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
        background-color: #f8f8f8;
        overflow: hidden;
    }

    .top_menu {
        background-color: #fff;
        width: 100%;
        padding: 20px 0 15px;
        box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
    }

    .top_menu .buttons {
        margin: 3px 0 0 20px;
        position: absolute;
    }

    .top_menu .buttons .button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        position: relative;
    }

    .top_menu .buttons .button.close_button {
        background-color: #f5886e;
    }

    .top_menu .buttons .button.minimize {
        background-color: #fdbf68;
    }

    .top_menu .buttons .button.maximize {
        background-color: #a3d063;
    }

    .top_menu .title {
        text-align: center;
        color: #bcbdc0;
        font-size: 20px;
    }

    .messages {
        position: relative;
        list-style: none;
        padding: 20px 10px 0 10px;
        margin: 0;
        height: 347px;
        overflow: scroll;
    }

    .messages .message {
        clear: both;
        overflow: hidden;
        margin-bottom: 20px;
        transition: all 0.5s linear;
        opacity: 0;
    }

    .messages .message.left .avatar {
        background-color: #f5886e;
        float: left;
    }

    .messages .message.left .text_wrapper {
        background-color: #ffe6cb;
        margin-left: 20px;
    }

    .messages .message.left .text_wrapper::after, .messages .message.left .text_wrapper::before {
        right: 100%;
        border-right-color: #ffe6cb;
    }

    .messages .message.left .text {
        color: #c48843;
    }

    .messages .message.right .avatar {
        background-color: #fdbf68;
        float: right;
    }

    .messages .message.right .text_wrapper {
        background-color: #c7eafc;
        margin-right: 20px;
        float: right;
    }

    .messages .message.right .text_wrapper::after, .messages .message.right .text_wrapper::before {
        left: 100%;
        border-left-color: #c7eafc;
    }

    .messages .message.right .text {
        color: #45829b;
    }

    .messages .message.appeared {
        opacity: 1;
    }

    .messages .message .avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: inline-block;
    }

    .messages .message .text_wrapper {
        display: inline-block;
        padding: 20px;
        border-radius: 6px;
        width: calc(100% - 85px);
        min-width: 100px;
        position: relative;
    }

    .messages .message .text_wrapper::after, .messages .message .text_wrapper:before {
        top: 18px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .messages .message .text_wrapper::after {
        border-width: 13px;
        margin-top: 0px;
    }

    .messages .message .text_wrapper::before {
        border-width: 15px;
        margin-top: -2px;
    }

    .messages .message .text_wrapper .text {
        font-size: 18px;
        font-weight: 300;
    }

    .bottom_wrapper {
        position: relative;
        width: 100%;
        background-color: #fff;
        padding: 20px 20px;
        position: absolute;
        bottom: 0;
    }

    .bottom_wrapper .message_input_wrapper {
        display: inline-block;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #bcbdc0;
        width: calc(100% - 160px);
        position: relative;
        padding: 0 20px;
    }

    .bottom_wrapper .message_input_wrapper .message_input {
        border: none;
        height: 100%;
        box-sizing: border-box;
        width: calc(100% - 40px);
        position: absolute;
        outline-width: 0;
        color: gray;
    }

    .bottom_wrapper .send_message {
        width: 140px;
        height: 50px;
        display: inline-block;
        border-radius: 50px;
        background-color: #a3d063;
        border: 2px solid #a3d063;
        color: #fff;
        cursor: pointer;
        transition: all 0.2s linear;
        text-align: center;
        float: right;
    }

    .bottom_wrapper .send_message:hover {
        color: #a3d063;
        background-color: #fff;
    }

    .bottom_wrapper .send_message .text {
        font-size: 18px;
        font-weight: 300;
        display: inline-block;
        line-height: 48px;
    }

    .message_template {
        display: none;
    }
    #sound-clip{
        display: none;
    }

    #container{
        width: 600px;
        height: 400px;
        position: absolute;
    }
</style>
<script>
import * as Three from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { core } from '../../config/pluginInit'
import axios from 'axios'
import $ from 'jquery'
export default {
  name: 'AIStudio',
  components: {

  },
  data () {
    return {
      imgSrc: null,
      videoSrc: null,
      imgFile: null,
      videoFile: null,
      userName: null,
      state: 'SUCCESS',
      lang: 'ko-KR',
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      publicPath: 'process.env.BASE_URL',
      loader: null,
      loader2: null
    }
  },
  mounted () {
    core.index()
    this.init()
    this.greet()
    setTimeout(() => {
      this.microponeTest()
    }, 500)
  },
  methods: {
    init: function () {
      const ths = this
      const container = document.getElementById('container')
      ths.camera = new Three.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 1000)
      ths.camera.position.z = 120
      ths.camera.position.y = 80
      ths.scene = new Three.Scene()
      ths.loader = new GLTFLoader()
      ths.loader.load('/media/scene.gltf', (gltf) => {
        console.log(gltf)
        ths.scene.add(gltf.scene)
        ths.renderer = new Three.WebGLRenderer({ alpha: true, antialias: true })
        ths.renderer.setSize(container.clientWidth, container.clientHeight)
        console.log(container)
        container.appendChild(ths.renderer.domElement)
        ths.animate()
      })
    },

    animate: function () {
      const ths = this
      requestAnimationFrame(ths.animate)
      ths.renderer.render(ths.scene, ths.camera)
    },

    changeLang (e) {
      const ths = this
      ths.lang = e.target.value
    },
    handleImageSource (event) {
      console.log(event.target.files)
      this.imgFile = event.target.files[0]
    },
    handleVideoSource (event) {
      console.log(event.target.files)
      this.videoFile = event.target.files[0]
    },
    translateVideo () {
      const formData = new FormData()
      formData.append('img', this.imgFile)
      formData.append('movie', this.videoFile)
      const rs = axios.post('/api/v1/files/translateVideo/', formData)
      rs.then((response) => {
        console.log(response)
      })
    },
    // variables
    messageFunc (arg) {
      this.text = arg.text
      this.messageSide = arg.messageSide

      this.draw = (function (_this) {
        return function () {
          const $message = $($('.message_template').clone().html())
          $message.addClass(_this.messageSide).find('.text').html(_this.text)
          $('.messages').append($message)

          return setTimeout(function () {
            return $message.addClass('appeared')
          }, 0)
        }
      }(this))
      return this
    },
    getMessageText () {
      const $messageInput = $('.message_input')
      return $messageInput.val()
    },
    sendMessage (text, messageSide) {
      const ths = this
      $('.message_input').val('')
      const $messages = $('.messages')
      const message = ths.messageFunc({
        text: text,
        messageSide: messageSide
      })
      message.draw()
      $messages.animate({ scrollTop: $messages.prop('scrollHeight') }, 300)
    },
    greet () {
      const ths = this
      setTimeout(function () {
        return ths.sendMessage('Kochat 데모에 오신걸 환영합니다.', 'left')
      }, 1000)

      setTimeout(function () {
        return ths.sendMessage('사용할 닉네임을 알려주세요.', 'left')
      }, 2000)
    },
    onClickAsEnter (e) {
      const ths = this
      if (e.keyCode === 13) {
        ths.onSendButtonClicked()
      }
    },
    setUserName (username) {
      const ths = this
      if (username != null && username.replace(' ', '')) {
        setTimeout(function () {
          return ths.sendMessage('반갑습니다.' + username + '님. 닉네임이 설정되었습니다.', 'left')
        }, 1000)
        setTimeout(function () {
          return ths.sendMessage('저는 각종 여행 정보를 알려주는 여행봇입니다.', 'left')
        }, 2000)
        setTimeout(function () {
          return ths.sendMessage('날씨, 미세먼지, 여행지, 맛집 정보에 대해 무엇이든 물어보세요!', 'left')
        }, 3000)

        return username
      } else {
        setTimeout(function () {
          return ths.sendMessage('올바른 닉네임을 이용해주세요.', 'left')
        }, 1000)

        return null
      }
    },
    requestChat (messageText, urlPattern) {
      const ths = this
      $.ajax({
        url: '/chat/' + urlPattern + '/' + ths.userName + '/' + messageText,
        type: 'GET',
        dataType: 'json',
        success: function (data) {
          ths.state = data.state

          if (ths.state === 'SUCCESS') {
            return ths.sendMessage(data.answer, 'left')
          } else if (ths.state === 'REQUIRE_LOCATION') {
            return ths.sendMessage('어느 지역을 알려드릴까요?', 'left')
          } else {
            return ths.sendMessage('죄송합니다. 무슨말인지 잘 모르겠어요.', 'left')
          }
        },
        error: function (request, status, error) {
          console.log(error)

          return ths.sendMessage('죄송합니다. 서버 연결에 실패했습니다.', 'left')
        }
      })
    },
    onSendButtonClicked () {
      const ths = this
      const messageText = ths.getMessageText()
      ths.sendMessage(messageText, 'right')

      if (this.userName == null) {
        this.userName = ths.setUserName(messageText)
      } else {
        if (messageText.includes('안녕')) {
          setTimeout(function () {
            return ths.sendMessage('안녕하세요. 저는 Kochat 여행봇입니다.', 'left')
          }, 1000)
        } else if (messageText.includes('고마워')) {
          setTimeout(function () {
            return ths.sendMessage('천만에요. 더 물어보실 건 없나요?', 'left')
          }, 1000)
        } else if (messageText.includes('없어')) {
          setTimeout(function () {
            return ths.sendMessage('그렇군요. 알겠습니다!', 'left')
          }, 1000)
        } else if (this.state.includes('REQUIRE')) {
          return ths.requestChat(messageText, 'fill_slot')
        } else {
          return ths.requestChat(messageText, 'request_chat')
        }
      }
    },
    microponeTest () {
      const ths = this
      // Set up the AudioContext.
      // const audioCtx = new AudioContext()

      // Top-level variable keeps track of whether we are recording or not.
      let recording = false
      console.log(navigator.mediaDevices)
      // Ask user for access to the microphone.
      if (navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          // Instantiate the media recorder.
          const mediaRecorder = new MediaRecorder(stream)

          // Create a buffer to store the incoming data.
          let chunks = []
          mediaRecorder.ondataavailable = (event) => {
            console.log('event', event)
            chunks.push(event.data)
          }

          // When you stop the recorder, create a empty audio clip.
          mediaRecorder.onstop = (event) => {
            const audio = new Audio()
            audio.setAttribute('controls', '')
            $('#sound-clip').append(audio)
            $('#sound-clip').append('<br />')

            // Combine the audio chunks into a blob, then point the empty audio clip to that blob.
            const blob = new Blob(chunks, { type: 'audio/webm; codecs=opus' })
            audio.src = window.URL.createObjectURL(blob)
            const formData = new FormData()
            formData.append('file', blob, 'audio.webm')
            formData.append('lang', ths.lang)
            const rs = axios.post('/api/v1/files/speech_to_text/', formData)
            rs.then((response) => {
              if (response?.data?.error === '0001') {
                return false
              }
              const audio = new Audio()
              audio.setAttribute('controls', '')
              $('#sound-clip').append(audio)
              $('#sound-clip').append('<br />')
              audio.src = '/media/output.mp3?r=' + Math.random()
              audio.currentTime = 0
              audio.play()
            })

            // Clear the `chunks` buffer so that you can record again.
            chunks = []
          }

          // Set up event handler for the "Record" button.
          $('#record').on('click', () => {
            if (recording) {
              mediaRecorder.stop()
              recording = false
              $('#record').html('Record')
            } else {
              mediaRecorder.start()
              recording = true
              $('#record').html('Stop')
            }
          })
        }).catch((err) => {
          console.log(err)
          // Throw alert when the browser is unable to access the microphone.
          alert("Oh no! Your browser cannot access your computer's microphone.")
        })
      } else {
        // Throw alert when the browser cannot access any media devices.
        alert("Oh no! Your browser cannot access your computer's microphone. Please update your browser.")
      }
    }
  }
}
</script>
